import { Select, SpacerV } from '@cb/apricot-react'
import CBData from '@cb/apricot/CBData'
import {
  validateExactLength,
  validateLimitedCharacterStringWithWildCard,
  validateNumber,
} from '../../../../utils/validations'
import { QUERY_GET_APP_INFO, QUERY_GET_OSRS_EVENTS } from '../../../../services/graphql/queries/queries'
import { useQuery } from '@apollo/client'
import CheckboxColumns from '../../../_common/forms/checkboxcolumns/CheckboxColumns'
import { getQueryDataWithoutKey } from '../../../../services/graphql/utils'
import orderBy from 'lodash/orderBy'
import ValidationInput from '../../../_common/forms/validationinput/ValidationInput'
import DateFieldsInput from '../../../_common/forms/datefieldsinput/DateFieldsInput'
import {
  BYPERSON_FIELD_AI,
  BYPERSON_FIELD_FN,
  BYPERSON_FIELD_LN,
  BYPERSON_FIELD_APP,
  BYPERSON_FIELD_DOB,
  BYPERSON_FIELD_EMAIL,
  BYPERSON_FIELD_STATE,
} from './utils'
import isEmpty from 'lodash/isEmpty'

const STATES = [
  { label: '', value: '' },
  ...CBData.states.reduce((acc, s) => (s.value ? [...acc, { label: s.name, value: s.value }] : acc), []),
]

const WILDCARD_VALIDATION = {
  validate: value => Boolean(validateLimitedCharacterStringWithWildCard(value, 2)) || isEmpty(value),
  helperMsg: 'Please enter at least two characters. Only trailing wildcards "*" are permitted.',
  errorMsg: 'Error: The value needs to be 2 characters excluding any wildcards.',
}

const getDefaultDOB = (value = '') => {
  const isFullDOB = value.length === 8
  return {
    defaultMonth: value?.substring(0, 2),
    defaultDay: isFullDOB ? value.substring(2, 4) : '',
    defaultYear: isFullDOB ? value.substring(4, 8) : value.substring(2, 6),
  }
}

const SearchByPersonalInfo = ({ savedCriteria, disableFields, onFieldChange }) => {
  const appData = getQueryDataWithoutKey(useQuery(QUERY_GET_APP_INFO)) || null
  const osrsEvents = getQueryDataWithoutKey(useQuery(QUERY_GET_OSRS_EVENTS)) || null
  console.log('osrsEvents', JSON.stringify(osrsEvents, null, 2))

  return (
    <fieldset disabled={disableFields}>
      <legend className="sr-only">Search by Personal Information</legend>
      <p className="cb-font-size-small cb-margin-bottom-32 cb-gray1-color">
        <strong>Instructions: </strong>Searching by student email address, only this field needs to be entered. Otherwise, please
        enter input for at least 2 of the fields to search.
      </p>
      <ValidationInput
        {...WILDCARD_VALIDATION}
        label="First Name"
        maxLength={20}
        floating
        floatingBg="light"
        name={BYPERSON_FIELD_FN}
        defaultValue={savedCriteria[BYPERSON_FIELD_FN] || ''}
        onChange={(value, state) => onFieldChange(BYPERSON_FIELD_FN, value, state)}
      />
      <SpacerV size={24} />
      <ValidationInput
        {...WILDCARD_VALIDATION}
        label="Last Name"
        maxLength={35}
        floating
        floatingBg="light"
        name={BYPERSON_FIELD_LN}
        defaultValue={savedCriteria[BYPERSON_FIELD_LN] || ''}
        onChange={(value, state) => onFieldChange(BYPERSON_FIELD_LN, value, state)}
      />
      <SpacerV size={16} />
      <DateFieldsInput
        label="Date of Birth"
        {...getDefaultDOB(savedCriteria[BYPERSON_FIELD_DOB])}
        onChange={(day = '', month = '', year = '', state) => onFieldChange(BYPERSON_FIELD_DOB, `${month}${day}${year}`, state)}
        fieldName={BYPERSON_FIELD_DOB}
        validate={(day, month, year) => ({
          monthError: year && !month ? 'Error: Month is required in order to search by DOB' : null,
          yearError:
            month && !year
              ? 'Error: Year is required in order to search by DOB'
              : year && (!validateExactLength(year, 4) || !validateNumber(year))
              ? 'Error: Year must have 4 digits'
              : null,
        })}
      />
      <SpacerV size={24} />
      {/* Student Email Address has no validation function or error messaging */}
      <ValidationInput
        helperMsg="Please enter Student Account Email Address which may be different from OKTA Login ID"
        label="Student Email Address"
        maxLength={50}
        floating
        floatingBg="light"
        name={BYPERSON_FIELD_EMAIL}
        defaultValue={savedCriteria[BYPERSON_FIELD_EMAIL] || ''}
        onChange={e => onFieldChange(BYPERSON_FIELD_EMAIL, e)}
        onClear={e => onFieldChange(BYPERSON_FIELD_EMAIL, '')}
      />
      <SpacerV size={24} />
      <ValidationInput
        label="AI Code"
        maxLength={6}
        validate={value => Boolean(validateExactLength(value, 6) && validateNumber(value)) || isEmpty(value)}
        helperMsg="Please enter 6 digits"
        errorMsg="Error: The value needs to be 6 digits."
        floating
        floatingBg="light"
        name={BYPERSON_FIELD_AI}
        defaultValue={savedCriteria[BYPERSON_FIELD_AI] || ''}
        onChange={(value, state) => onFieldChange(BYPERSON_FIELD_AI, value, state)}
      />
      <SpacerV size={24} />
      <Select
        label="State"
        values={STATES}
        name={BYPERSON_FIELD_STATE}
        defaultValue={savedCriteria[BYPERSON_FIELD_STATE]}
        floating
        floatingBg="light"
        onChange={value => onFieldChange(BYPERSON_FIELD_STATE, value)}
      />
      <SpacerV size={24} />
      {appData ? (
        <CheckboxColumns
          checkboxes={orderBy(
            Object.keys(appData).map(value => ({ value, label: appData[value] })),
            'label',
            'asc'
          )}
          legend="Mapped Applications"
          name={BYPERSON_FIELD_APP}
          selected={savedCriteria[BYPERSON_FIELD_APP]}
          onChange={selections => onFieldChange(BYPERSON_FIELD_APP, selections)}
        />
      ) : null}
    </fieldset>
  )
}

SearchByPersonalInfo.propTypes = {
  savedCriteria: PropTypes.object.isRequired,
  disableFields: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
}

export default SearchByPersonalInfo
