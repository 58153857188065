import _ from 'lodash'
import React from "react"

import { isDateWithinRange, formatDateToEasternTime } from '../../../utils/date'
import { usePropertiesQuery } from '../../../services/graphql/utils'
import CBNotification from "@cb/apricot/CBNotification"

const GENERIC_DOWNTIME_MESSAGE = "Maintenance is in progress. Certain features may be unavailable during this period.  "
const DowntimeBanner = () => {
  // Grab the properties from the database (this should be cached)
  const { data: props = {} } = usePropertiesQuery()
  
  const showDowntimeBanner = isDateWithinRange(props.downtimeBannerVisibilityStartDate, props.downtimeEndDate)
  const downtimeActive = isDateWithinRange(props.downtimeStartDate, props.downtimeEndDate)
  const header = downtimeActive ? "Maintenance in Progress" : "Upcoming Maintenance"
  const message = props.downtimeNotificationMessage ? _.template(props.downtimeNotificationMessage)({startPeriod: formatDateToEasternTime(props.downtimeStartDate).trim(), endPeriod: formatDateToEasternTime(props.downtimeEndDate).trim()}) : GENERIC_DOWNTIME_MESSAGE
  
  // Activate the X "close" button.  
  React.useEffect(() => {
    CBNotification.Dismissible({
      elem: document.querySelector('.cb-notification-dismissible') 
    })
  })
  
  return showDowntimeBanner ? (
    <div className="cb-downtime-banner cb-sticky cb-sticky-top cb-sticky-active z-index-999 cb-white-bg" data-cbtrack-comp="apricot-react:sticky">
      <div className="cb-notification cb-notification-dismissible" 
        role="region" 
        aria-labelledby="d3"
        data-cbtrack-comp="apricot-react:notification"
      >
        <div className="cb-notification-container">
          <div className="cb-notification-header">
            <span className="cb-icon cb-icon-circular cb-exclamation cb-red1-color" aria-hidden="true"></span>
            <h4 className="cb-notification-title" id="d3">{header}</h4>
            
            { !downtimeActive ? (
              <button type="button" className="cb-downtime-banner-close-btn cb-btn cb-btn-square cb-btn-greyscale cb-btn-close" aria-describedby="d3" data-cb-notification-close>
                <span className="cb-icon cb-x-mark" aria-hidden="true"></span>
                <span className="sr-only">Close</span>
              </button>
            ) : '' }
          </div>
          
          <div className="cb-notification-content">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
    ) : (<></>)
}

export default DowntimeBanner
